import React from 'react';
import './UserPage.css';  // スタイルファイルをインポート
import UserIconSample from '../assets/iconsample.png'; // 画像のインポート

const UserPage = () => {
  return (
    <body className='userpage'>
        <div className="user-profile">
            <img src={UserIconSample} alt="User Icon" className='icon'/>
            <h2 className='user-name'>ユーザーネーム</h2>
            <div className='profile-sentence'>
                <p>プロフィール</p>
                <p>〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇</p>
            </div>
        </div>
        <div className="user-material">
            <p>最新の教材</p>
        </div>
    </body>
  );
};

export default UserPage;
