import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // useNavigateをインポート
import './Upload.css';  // スタイルファイルをインポート

const Upload = () => {

    const [schoolType, setSchoolType] = useState('');
    const [subject, setSubject] = useState('');
    const [fileType, setFileType] = useState('');
    const [file, setFile] = useState(null);  // ファイルの状態を管理
    const navigate = useNavigate(); // useNavigateを使ってnavigateを定義

    const schoolTypes = ['小学校', '中学校', '高等学校', 'その他校種'];
    const subjects = ['国語', '社会', '地歴公民', '公民', '算数', '数学', '理科', '生活', '音楽', '芸術', '図画工作', '技術家庭', '家庭', '保健体育', '外国語活動', '外国語', '専門科目'];
    const fileTypes = ['PowerPoint', 'PDF', 'Word', 'Excel' , '画像形式'];

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);  // 選択したファイルを状態に保存
    };

    const getCsrfToken = () => {
        // CSRFトークンをクッキーから取得するロジック
        const cookies = document.cookie.split('; ');
        const csrfToken = cookies.find(row => row.startsWith('csrftoken='));
        return csrfToken ? csrfToken.split('=')[1] : '';
    };

    const handleUpload = async () => {
        if (!file) {
            alert('ファイルが選択されていません。');
            return;
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('schoolType', schoolType);
        formData.append('subject', subject);
        formData.append('fileType', fileType);

        try {
            const response = await axios.post('http://localhost:8000/api/upload/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-CSRFToken': getCsrfToken(), // この行の書式が問題ないか確認
                }                
            });
            if (response.status === 200) {
                console.log('Upload successful:', response.data);
                alert('ファイルが正常にアップロードされました。');
                navigate('/mypage'); // アップロード成功時にマイページに遷移
            } else {
                throw new Error('Upload failed with status: ' + response.status);
            }
        } catch (error) {
            console.error('Upload failed:', error);
            alert('ファイルのアップロードに失敗しました。');
        }
    };



    return (
        <div className='uploadpage'>
            <h2>資料をアップロード</h2>
            <div className='upload-item'>
                <div className='type'>
                    <div className='item'>
                        <p>学校種別</p>
                    </div>
                    <select value={schoolType} onChange={e => setSchoolType(e.target.value)} className="select">
                        <option value="">----学校種別を選択----</option>  {/* デフォルト選択肢を追加 */}
                        {schoolTypes.map((type, index) => (
                            <option key={index} value={type}>{type}</option>
                        ))}
                    </select>
                </div>
                <div className='type'>
                    <div className='item'>
                        <p>教　　科</p>
                    </div>
                    <select value={subject} onChange={e => setSubject(e.target.value)} className="select">
                        <option value="">----教科を選択----</option>  {/* デフォルト選択肢を追加 */}
                        {subjects.map((type, index) => (
                            <option key={index} value={type}>{type}</option>
                        ))}
                    </select>
                </div>
                <div className='type'>
                    <div className='item'>
                        <p>ファイル形式</p>
                    </div>
                    <select value={fileType} onChange={e => setFileType(e.target.value)} className="select">
                        <option value="">--ファイル形式を選択--</option>  {/* デフォルト選択肢を追加 */}
                        {fileTypes.map((type, index) => (
                            <option key={index} value={type}>{type}</option>
                        ))}
                    </select>
                </div>
                <div className="file-upload">
                    <input type="file" onChange={handleFileChange} />
                </div>
                <div className='alert'>
                    <div className='alert-sentence'>
                    以下の拡張子を持つファイルのみアップロード可能です。
                        <div className='file-item'>
                            <div className='file-title'>画像ファイル:</div>
                            <div className='extension'>.jpg, .jpeg, .png, .gif, .bmp, .tiff, .svg</div>
                        </div>
                        <div className='file-item'>
                            <div className='file-title'>Microsoft Office ファイル:</div>
                            <div className='extension'>.doc, .docx, .xls, .xlsx, .ppt, .pptx</div>
                        </div>
                        <div className='file-item'>
                            <div className='file-title'>その他の関連ファイル形式:</div>
                            <div className='extension'>.pdf, .txt, .rtf, .odt, .ods, .odp, .epub, .mobi, .csv, .tsv, .json, 
                            .zip, .rar, .7z, .tar, .gz, .xml, .md, .html, .htm, .log, .psd, .ai</div>
                        </div>
                    </div>
                </div>
                <h2>アップロードする上での注意事項</h2>
                <div className="message">
                    <div className="message-box">
                        <p className='message-item'>1.個人情報や機密情報の取り扱い:</p>
                        <p>アップロードするコンテンツに、他人の個人情報（名前、住所、電話番号、メールアドレス、顔写真など）や機密情報（職務上知り得た情報）が含まれていないか確認してください。個人情報や機密情報が含まれている場合、そのコンテンツは削除対象となり得ます。</p> 
                        <p className='message-item'>2.プライバシーに関する注意:</p>
                        <p>教育資料や資料に含まれる個人やグループのプライバシーを侵害する内容は、アップロードしないでください。プライバシーの侵害は法的問題を引き起こす可能性があります。</p> 
                        <p className='message-item'>3.品質と正確性:</p>
                        <p>アップロードするコンテンツは、正確で信頼性のある情報であることを確認してください。誤った情報や誤解を招く内容は、他のユーザーに誤解を与え、教育の質を損なう可能性があります。</p> 
                        <p className='message-item'>4.ウイルスやマルウェアの禁止:</p>
                        <p>ウイルス、マルウェア、その他の有害なソフトウェアを含むファイルのアップロードは禁止されています。これらのファイルは、サイトの安全性や他のユーザーのシステムに悪影響を与える可能性があります。</p> 
                        <p className='message-item'>5.コンテンツの適切性:</p>
                        <p>アップロードするコンテンツが、教育的な目的に適しているかどうかを確認してください。暴力的、差別的、または不適切な内容は削除される場合があります。</p> 
                        <p className='message-item'>6.定期的なコンテンツの見直し:</p>
                        <p>アップロードしたコンテンツが時間経過により古くなったり、不適切となる可能性があります。そのため、定期的に自分のコンテンツを見直し、必要に応じて更新や削除を行ってください。</p> 
                        <p className='message-item'>7.著作権について:</p>
                        <p>アップロードするすべてのコンテンツは、著作権法の対象となる可能性があります。他者の著作物を無断でアップロードすることは、法律違反となる場合がありますのでご注意ください。</p> 
                        <p className='message-item'>8.教科書の内容について:</p>
                        <p>教科書やその他の教育資料の内容は、著作権で保護されています。これらの資料をアップロードする場合は、必ず事前に著作権者の許可を得てください。</p> 
                        <p className='message-item'>9.著作権者の許可:</p>
                        <p>アップロードするコンテンツに著作権がある場合、アップロード前に必ず著作権者から許可を取得してください。許可を得ていないコンテンツのアップロードは厳禁です。</p> 
                        <p className='message-item'>10.責任について:</p>
                        <p>アップロードされたコンテンツの著作権に関しては、アップロードしたユーザーがすべての責任を負います。違法なコンテンツのアップロードは法律により罰せられる可能性があります。</p> 
                        <p className='message-item'>11.不適切な内容について:</p>
                        <p>不適切と判断されるコンテンツは、予告なく削除されることがあります。違反内容が確認された場合、アップロードしたユーザーは処罰の対象となる可能性があります。</p> 
                        <p className='message-item'>12.アカウントの凍結:</p>
                        <p>不適切な使い方や著作権侵害が確認された場合、該当ユーザーのアカウントを凍結する可能性があります。ルールを遵守し、適切な利用をお願いします。</p>  
                    </div>
                </div>
                <div className="file-upload-fin">
                    <button onClick={handleUpload}>ファイルをアップロードする</button>
                </div> 
            </div>     
        </div>
      );
    };
export default Upload;