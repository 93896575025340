import React from 'react';
import './MainContent.css';
import { Link } from 'react-router-dom';
import libraryImage from '../assets/library.jpg'; // 画像のインポート

const MainContent = () => {
  return (
    <main className="main-content">
      <div className="image-container">
        <img src={libraryImage} alt="Library" className="library-image" />
        <div className="overlay"></div>
        <div className="welcome-text">ようこそedushareへ</div>
        <div className="description-text">
          授業者たちが、クリエイティブな<br/>アイディアを共有し合う場所
        </div>
      </div>
      <div className="main-btn">
        <Link to="./MaterialSearch" class="MaterialSearch">アイディアを探索する</Link>
      </div>
      <div className="materials">
      <p className="latest">最新の資料</p>
      <Link to="/Userpage">ユーザー</Link>
      <Link to="/MaterialDetail">教材詳細</Link>
      <p className="more">もっとみる</p>
      <p className="recommend">オススメの資料</p>
      <p className="more">もっとみる</p>
      </div>
    </main>
  );
};

export default MainContent;
