import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // ページ遷移用のRouter
import './App.css';  // グローバルCSSのインポート
import Header from './components/Header';  // Header コンポーネントをインポート
import Footer from './components/Footer';  // Footer コンポーネントをインポート
import MainContent from './components/MainContent';  // MainContent コンポーネントをインポーネット
import Register from './components/Register';  // Register コンポーネントをインポート
import Subject from './components/Subject';  // 教科ごとのページ表示用コンポーネント
import MaterialSearch from './components/MaterialSearch';  // MaterialSearch コンポーネントをインポート
import Login from './components/Login';  // Login コンポーネントをインポート
import UserPage from './components/UserPage';  // UserPage コンポーネントをインポート
import MyPage from './components/MyPage';  // MyPage コンポーネントをインポート
import Upload from './components/Upload';  // Upload コンポーネントをインポート
import MaterialDetail from './components/MaterialDetail';  // MaterialDetail コンポーネントをインポート

function App() {
  return (
    <Router>
      <div className="App">

        {/* Header コンポーネントを使用 */}
        <Header />  

        <Routes>
          {/* トップページ */}
          <Route path="/" element={<MainContent />} />

          {/* 会員登録ページ */}
          <Route path="/register" element={<Register />} />

          {/* 教材探索ページ */}
          <Route path="/MaterialSearch" element={<MaterialSearch />} />

          {/* ログインページ */}
          <Route path="/Login" element={<Login />} />

          {/* ユーザーページ */}
          <Route path="/UserPage" element={<UserPage />} />

          {/* マイページ */}
          <Route path="/MyPage" element={<MyPage />} />

          {/* アップロードページ */}
          <Route path="/Upload" element={<Upload />} />

          {/* 教材詳細ページ */}
          <Route path="/MaterialDetail" element={<MaterialDetail />} />

          {/* 教科ごとのページ */}
          <Route path="/elem-ja" element={<Subject subjectTitle="小学校国語" subjectContent="小学校国語の内容" />} />
          <Route path="/elem-so" element={<Subject subjectTitle="小学校社会" subjectContent="小学校社会の内容" />} />
          <Route path="/elem-ma" element={<Subject subjectTitle="小学校算数" subjectContent="小学校算数の内容" />} />
          <Route path="/elem-sc" element={<Subject subjectTitle="小学校理科" subjectContent="小学校理科の内容" />} />
          <Route path="/elem-li" element={<Subject subjectTitle="小学校生活" subjectContent="小学校生活の内容" />} />
          <Route path="/elem-mu" element={<Subject subjectTitle="小学校音楽" subjectContent="小学校音楽の内容" />} />
          <Route path="/elem-ar" element={<Subject subjectTitle="小学校図画工作" subjectContent="小学校図画工作の内容" />} />
          <Route path="/elem-ho" element={<Subject subjectTitle="小学校家庭" subjectContent="小学校家庭の内容" />} />
          <Route path="/elem-ph" element={<Subject subjectTitle="小学校保健体育" subjectContent="小学校保健体育の内容" />} />
          <Route path="/elem-fo" element={<Subject subjectTitle="小学校外国語活動" subjectContent="小学校外国語活動の内容" />} />
          <Route path="/juni-ja" element={<Subject subjectTitle="中学校国語" subjectContent="中学校国語の内容" />} />
          <Route path="/juni-so" element={<Subject subjectTitle="中学校社会" subjectContent="中学校社会の内容" />} />
          <Route path="/juni-ma" element={<Subject subjectTitle="中学校数学" subjectContent="中学校数学の内容" />} />
          <Route path="/juni-sc" element={<Subject subjectTitle="中学校理科" subjectContent="中学校理科の内容" />} />
          <Route path="/juni-fo" element={<Subject subjectTitle="中学校外国語" subjectContent="中学校外国語の内容" />} />
          <Route path="/juni-mu" element={<Subject subjectTitle="中学校音楽" subjectContent="中学校音楽の内容" />} />
          <Route path="/juni-ar" element={<Subject subjectTitle="中学校美術" subjectContent="中学校美術の内容" />} />
          <Route path="/juni-ph" element={<Subject subjectTitle="中学校保健体育" subjectContent="中学校保健体育の内容" />} />
          <Route path="/juni-th" element={<Subject subjectTitle="中学校技術家庭" subjectContent="中学校技術家庭の内容" />} />
          <Route path="/high-ja" element={<Subject subjectTitle="高等学校国語" subjectContent="高等学校国語の内容" />} />
          <Route path="/high-gh" element={<Subject subjectTitle="高等学校地理歴史" subjectContent="高等学校地理歴史の内容" />} />
          <Route path="/high-pu" element={<Subject subjectTitle="高等学校公民" subjectContent="高等学校公民の内容" />} />
          <Route path="/high-ma" element={<Subject subjectTitle="高等学校数学" subjectContent="高等学校数学の内容" />} />
          <Route path="/high-sc" element={<Subject subjectTitle="高等学校理科" subjectContent="高等学校理科の内容" />} />
          <Route path="/high-fo" element={<Subject subjectTitle="高等学校外国語" subjectContent="高等学校外国語の内容" />} />
          <Route path="/high-ph" element={<Subject subjectTitle="高等学校保健体育" subjectContent="高等学校保健体育の内容" />} />
          <Route path="/high-ar" element={<Subject subjectTitle="高等学校芸術" subjectContent="高等学校芸術の内容" />} />
          <Route path="/high-ho" element={<Subject subjectTitle="高等学校家庭" subjectContent="高等学校家庭の内容" />} />
          <Route path="/high-sp" element={<Subject subjectTitle="高等学校専門科目" subjectContent="高等学校専門科目の内容" />} />
          <Route path="/kinder-garden" element={<Subject subjectTitle="幼稚園" subjectContent="幼稚園の内容" />} />
          <Route path="/support-sc" element={<Subject subjectTitle="特別支援学校" subjectContent="特別支援学校の内容" />} />
        </Routes>

        {/* Footer コンポーネントを使用 */}
        <Footer />
          
      </div>
    </Router>
  );
}

export default App;