import React from 'react';
import './Subject.css';

const SubjectPage = ({ subjectTitle, subjectContent }) => {
  return (
    <div className="subject-page">
      <h1>{subjectTitle}</h1>
      <p>{subjectContent}</p>
    </div>
  );
};

export default SubjectPage;