import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './MyPage.css';  // スタイルファイルをインポート
import UserIconSample from '../assets/iconsample.png'; // 画像のインポート

const MyPage = () => {

  return (
    <div className='userpage'>
        <div className="user-profile">
            <img src={UserIconSample} alt="User Icon" className='user-icon'/>
            <h2 className='user-name'>ユーザーネーム</h2>
            <div className='profile-sentence'>
                <p>プロフィール</p>
                <p>〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇</p>
            </div>
        </div>
        <div className="mypage-btns">
            <Link to="/Upload" className="mypage-btn">資料をアップロード</Link>
            <Link to="" className="mypage-btn">プロフィールを編集</Link>
        </div>
        <div className="user-material">
            <p>最新の教材</p>
        </div>
    </div>
  );
};

export default MyPage;
