import React from 'react';
import './Login.css';  // スタイルファイルをインポート

function Login() {

  return (
    <div className="login-container">
      <h2>ログイン</h2>
      <form>
        <div className='type'>
          <label htmlFor="username">ユーザー名：</label>
          <div className='box'>
            <input type="text" id="username" name="username" placeholder="ユーザー名" required />
          </div>
        </div>
        <div className='type'>
          <label htmlFor="password">パスワード：</label>
          <div className='box'>
            <input type="password" id="password" name="password" placeholder="パスワード" required />
          </div>
        </div>
      </form>
      <button type="submit">ログインする</button>
    </div>
  );
}

export default Login;
