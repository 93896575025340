import React from 'react';
import './MaterialDetail.css';  // スタイルファイルをインポート
import { Link } from 'react-router-dom';
import MaterialSample from '../assets/MaterialSample.png'; // 画像のインポート
const MaterialDetail = () => {
  return (
    <body className='material'>
        <h2 className="title">教材詳細</h2>
        <div className="material_detail">
            <img src={MaterialSample} alt="Material Icon" className='icon'/>   
                <div className='class_a'>
                    <div className='class_b'>
                        <p className='item_a'>学校種別</p>
                        <p className='item_b'>中学校</p>
                    </div>
                    <div className='class_b'>
                        <p className='item_a'>教科</p>
                        <p className='item_b'>数学</p>
                    </div>
                    <div className='class_b'>
                        <p className='item_a'>作成者</p>
                        <p className='item_b'>島崎孝俊</p>
                    </div>
                    <div className='class_b'>
                        <p className='item_a'>料金</p>
                        <p className='item_b'>¥200円</p>
                    </div>
                    <div className='class_b'>
                        <p className='item_a'>投稿日</p>
                        <p className='item_b'>2024/8/13</p>
                    </div>
                    <div className='class_b'>
                        <p className='item_a'>評価</p>
                        <p className='star'>★★★★★</p>
                    </div>
                    <div className='download_btn'>
                        <Link to="#" className="download">資料をダウンロード</Link>
                    </div>
                </div>
        </div>
            <div className="user-material">
        </div>
        <h2 className="title">レビュー一覧</h2>
    </body>
  );
};

export default MaterialDetail;
