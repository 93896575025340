import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../assets/logo.png';
import mushimegane from '../assets/mushimegane.png';

const Header = () => {
  const [activeTab, setActiveTab] = useState(null);

  const handleMouseEnter = (tab) => {
    setActiveTab(tab);
  };

  const handleMouseLeave = () => {
    setActiveTab(null);
  };

  const [keyword, setKeyword] = useState(''); // フリーワード検索用の状態

  const handleSearch = (e) => {
    e.preventDefault(); // デフォルトイベントの停止
    console.log(`Searching with keyword: ${keyword}`);
  };

      const subjects = {
        elementary: [
          <Link to="/elem-ja" className='sub'>国&emsp;&emsp;&emsp;語</Link>, 
          <Link to="/elem-so" className='sub'>社&emsp;&emsp;&emsp;会</Link>, 
          <Link to="/elem-ma" className='sub'>算&emsp;&emsp;&emsp;数</Link>, 
          <Link to="/elem-sc" className='sub'>理&emsp;&emsp;&emsp;科</Link>,
          <Link to="/elem-li" className='sub'>生&emsp;&emsp;&emsp;活</Link>, 
          <Link to="/elem-mu" className='sub'>音&emsp;&emsp;&emsp;楽</Link>, 
          <Link to="/elem-ar" className='sub'>図 画 工 作</Link>, 
          <Link to="/elem-ho" className='sub'>家&emsp;&emsp;&emsp;庭</Link>, 
          <Link to="/elem-ph" className='sub'>保 健 体 育</Link>,
          <Link to="/elem-fo" className='sub'>外国語活動</Link>
        ],
        juniorHigh: [
          <Link to="/juni-ja" className='sub'>国&emsp;&emsp;&emsp;語</Link>, 
          <Link to="/juni-so" className='sub'>社&emsp;&emsp;&emsp;会</Link>, 
          <Link to="/juni-ma" className='sub'>数&emsp;&emsp;&emsp;学</Link>, 
          <Link to="/juni-sc" className='sub'>理&emsp;&emsp;&emsp;科</Link>,
          <Link to="/juni-fo" className='sub'>外&emsp;国&emsp;語</Link>, 
          <Link to="/juni-mu" className='sub'>音&emsp;&emsp;&emsp;楽</Link>, 
          <Link to="/juni-ar" className='sub'>美&emsp;&emsp;&emsp;術</Link>, 
          <Link to="/juni-ph" className='sub'>保 健 体 育</Link>,
          <Link to="/juni-th" className='sub'>技 術 家 庭</Link>
        ],
        high: [
          <Link to="/high-ja" className='sub'>国&emsp;&emsp;&emsp;語</Link>, 
          <Link to="/high-gh" className='sub'>地 理 歴 史</Link>,
          <Link to="/high-pu" className='sub'>公&emsp;&emsp;&emsp;民</Link>,  
          <Link to="/high-ma" className='sub'>数&emsp;&emsp;&emsp;学</Link>, 
          <Link to="/high-sc" className='sub'>理&emsp;&emsp;&emsp;科</Link>,
          <Link to="/high-fo" className='sub'>外&emsp;国&emsp;語</Link>, 
          <Link to="/high-ph" className='sub'>保 健 体 育</Link>, 
          <Link to="/high-ar" className='sub'>芸&emsp;&emsp;&emsp;術</Link>, 
          <Link to="/high-ho" className='sub'>家&emsp;&emsp;&emsp;庭</Link>,
          <Link to="/high-sp" className='sub'>専 門 科 目</Link> 
        ],
        others: [
          <Link to="/kinder-garden" className='sub'>幼&emsp;稚&emsp;園</Link>, 
          <Link to="/support-sc" className='sub'>特別支援学校</Link>,
        ]
      };

      return (
        <header className="header">

          <div className="header-content">
            
            <a href="/">
              <img src={logo} alt="Logo" className="logo" />
            </a>

            <div className='btn-freeword'>
              <div className="header-btn">
                <Link to="/Login" className="btn mypage">ロ グ イ ン</Link>
                <Link to="/MyPage" className="btn mypage">マイページ</Link>
                <Link to="/Register" className="btn register">会 員 登 録</Link>
              </div>

              <div className="freeword-search">
                <form>
                  <label htmlFor="Keyword"></label>
                  <input type="text" id="Keyword" value={keyword} className="search-box" placeholder="何をお探しですか？" onChange={e => setKeyword(e.target.value)} />
                  <div className='search-item'>
                  <img src={mushimegane} alt="Search" className='mushimegane' onClick={handleSearch} style={{cursor: 'pointer'}} />
                </div>
                </form>
              </div>
            </div>
          </div>

          <nav className="tabs">
            {Object.keys(subjects).map((tab) => (
              <div key={tab} className={`tab ${tab}`} onMouseEnter={() => handleMouseEnter(tab)} onMouseLeave={handleMouseLeave}>
                <a href={`#${tab}`} className={`tab ${tab}`}>
                  {tab === 'elementary' ? '小　学　校' :
                  tab === 'juniorHigh' ? '中　学　校' :
                  tab === 'high' ? '高 等 学 校' :
                  'その他校種'}
                </a>
                {activeTab === tab && (
                  <div className="dropdown-menu">
                    {subjects[tab].map((subject, index) => (
                      <div key={index} className="dropdown-item">
                        {subject}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </nav>
        </header>
  )};
export default Header;
