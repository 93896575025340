import React, { useState } from 'react';
import './MaterialSearch.css'; // CSSファイルをインポート

const MaterialSearch = () => {

    // 状態管理用のフック
    const [schoolType, setSchoolType] = useState('');
    const [subject, setSubject] = useState('');
    const [fileType, setFileType] = useState('');
    const [keyword, setKeyword] = useState(''); // フリーワード検索用の状態

    // ドロップダウンの選択肢
    const schoolTypes = ['小学校', '中学校', '高等学校', 'その他校種'];
    const subjects = ['国語', '社会', '地歴公民', '公民', '算数', '数学', '理科', '生活', '音楽', '芸術', '図画工作', '技術家庭', '家庭', '保健体育', '外国語活動', '外国語', '専門科目'];
    const fileTypes = ['PowerPoint', 'PDF', 'Word', 'Excel' , '画像形式'];

    // 検索ボタンが押されたときの処理
    const handleSearch = () => {
        console.log(`Searching for: ${schoolType}, ${subject}, ${fileType}, with keyword: ${keyword}`);
        // ここに検索処理を実装します
    };
  
    return (
      <div className="search-container">
        <h2>教材検索</h2>
        <form>
          <label htmlFor="SchoolType">学校種別</label>
          <select value={schoolType} onChange={e => setSchoolType(e.target.value)} className="select">
                        <option value="">----学校種別を選択----</option>  {/* デフォルト選択肢を追加 */}
                        {schoolTypes.map((type, index) => (
                             <option key={index} value={type}>{type}</option>
                        ))}
          </select>
  
          <hr />
  
          <label htmlFor="Subject">教科</label>
          <select value={subject} onChange={e => setSubject(e.target.value)} className="select">
                        <option value="">----教科を選択----</option>
                        {subjects.map((subj, index) => (
                            <option key={index} value={subj}>{subj}</option>
                        ))}
                    </select>
  
          <hr />
  
          <label htmlFor="FileType">ファイル形式</label>
          <select value={fileType} onChange={e => setFileType(e.target.value)} className="select">
                        <option value="">--ファイル形式を選択--</option>
                        {fileTypes.map((type, index) => (
                            <option key={index} value={type}>{type}</option>
                        ))}
                    </select>
  
          <hr />

          <label htmlFor="Keyword">フリーワード</label>
          <input type="text" id="Keyword" value={keyword} onChange={e => setKeyword(e.target.value)} className="freeword"/>
          <div>
                <button onClick={handleSearch}>教材を検索する</button>
          </div>
        </form>
      </div>
    );
  };

export default MaterialSearch;